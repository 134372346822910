try {
    require("@popperjs/core");
    window.bootstrap = require("bootstrap");
    // window.$ = window.jQuery = require('jquery');
} catch (e) {}
let searchElement = document.querySelector(".search-popover");
if (searchElement) {
    var searchpopover = new bootstrap.Popover(searchElement, {
        container: "body",
        html: true,
        placement: "left",
        trigger: "click",
        sanitize: false,
    });
}

let offcanvasMenuItems = document.querySelectorAll(".menu-offcanvas .nav-link");
let offcanvasInstance;
const offcanvasElement = document.querySelector("body.landing-page #offcanvasmenu");
if (offcanvasElement) {
    offcanvasInstance = bootstrap.Offcanvas.getOrCreateInstance(offcanvasElement);
}

if (offcanvasInstance) {
    offcanvasMenuItems.forEach((item) => {
        item.addEventListener("click", () => {
            offcanvasInstance.hide();
        });
    });
}

window.onscroll = function () {
    myFunction();
};
var navbar = document.getElementById("navbar");
var navbarLP = document.getElementById("navbar-LP");
if (navbar) {
    var body = document.body;
    var sticky = navbar.offsetTop;
    var sticky = 44;
}
if (navbarLP) {
    var body = document.body;
    var sticky = navbarLP.offsetTop;
    var sticky = 44;
}
myFunction();

// Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
function myFunction() {
    if (navbar) {
        if (window.pageYOffset >= sticky) {
            navbar.classList.add("sticky");
            body.classList.add("down");
        } else {
            navbar.classList.remove("sticky");
            body.classList.remove("down");
        }
    }
    if (navbarLP) {
        if (window.scrollY >= sticky) {
            navbarLP.classList.add("sticky");
            body.classList.add("down");
        } else {
            navbarLP.classList.remove("sticky");
            body.classList.remove("down");
        }
    }
}

// Select surge elemens
const surge1 = document.querySelectorAll(".surge-1");
const surge2 = document.querySelectorAll(".surge-2");
const surge3 = document.querySelectorAll(".surge-3");
const surge4 = document.querySelectorAll(".surge-4");
const surgeLeft = document.querySelectorAll(".surge-left");
const surgeRight = document.querySelectorAll(".surge-right");

// Create observer
const observerSite = new IntersectionObserver((entries) => {
    // check if element is visible
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            // add class visible
            entry.target.classList.add("visible");
        }
    });
});

// check each element
if (surge1) {
    surge1.forEach((e) => {
        observerSite.observe(e);
    });
}
if (surge2) {
    surge2.forEach((e) => {
        observerSite.observe(e);
    });
}
if (surge3) {
    surge3.forEach((e) => {
        observerSite.observe(e);
    });
}
if (surge4) {
    surge4.forEach((e) => {
        observerSite.observe(e);
    });
}
if (surgeLeft) {
    surgeLeft.forEach((e) => {
        observerSite.observe(e);
    });
}
if (surgeRight) {
    surgeRight.forEach((e) => {
        observerSite.observe(e);
    });
}
